<template>
  <b-modal
    id="modal-export-master"
    ref="modalExportMaster"
    :title="`Export Produk Utama`"
    size="lg"
    scrollable
    ok-title="Export"
  >
    <b-row>
      <b-col>
        Berdasarkan hasil filter, {{ productData.length }} produk akan di export ke dalam file excel.
      </b-col>
    </b-row>
    <template #modal-footer>
        <button v-b-modal.modal-export-master-history class="btn btn-secondary">Export History</button>
        <button class="btn btn-danger" @click="$bvModal.hide('modal-export-master')">Close</button>
        <button v-b-modal.modal-export-master-button class="btn btn-primary">Submit</button>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BMedia,
  BImg,
  BTableSimple,
  BTh,
  BTr,
  BTd,
  BTbody,
  BThead,
  BButton,
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: '',
  props: {
    productData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      shopOptions: [],
    };
  },
  components: {
    BRow,
    BCol,
    BModal,
    BMedia,
    BImg,
    BTableSimple,
    BTh,
    BTr,
    BTd,
    BTbody,
    BThead,
    BButton,
    BCard,
    BTabs,
    BTab,
  },
  async mounted() {
  },
  methods: {
  },
});
</script>
